import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { useIsJsEnabled } from "gatsby-plugin-js-fallback"

import SiteLayout from "../components/SiteLayout"
import Seo from "../components/Seo"

import { toggleBackground } from "../state/background"
import { setBreadcrumbs } from "../state/breadcrumbs"
import { toggleStickyHeaderColour } from "../state/header"

import Slider from "../components/Slider"
import Chevron from "../components/Symbols/chevron"
import PageLink from "../components/PageLink"
import Reveal from "../components/Waypoints"
import FeaturedWork from "../components/FeaturedWork"
import ProjectMedia from "../components/Project/ProjectMedia"
import ProjectRegionDisplay from "../components/Project/ProjectRegionDisplay"

const Project = props => {
  const [heroHeight, setHeroHeight] = useState(null)
  const [mainContent, toggleMainContent] = useState(false)
  const sectionHero = useRef(null)
  const {
    dispatch,
    location,
    transitionStatus,
    data: { allWp, allWpProject },
  } = props
  const {
    title,
    slug,
    seo,
    projectFields,
    featuredWork,
  } = allWpProject.edges[0].node

  const isJsEnabled = useIsJsEnabled()

  useEffect(() => {
    // update breadcrumbs state
    dispatch(
      setBreadcrumbs({
        current: {
          title:
            projectFields.projectBreadcrumbTitle != null
              ? projectFields.projectBreadcrumbTitle
              : title,
          path: slug,
        },
        parent: {
          title: `Work`,
          path: `/work`,
          colour: `white`,
        },
      })
    )
  }, [])

  useEffect(() => {
    // if first load change bg colour instantly,
    // else from internal link, delay for swipe transition
    if (transitionStatus === "exited") {
      setTimeout(() => {
        dispatch(toggleStickyHeaderColour("white"))
        dispatch(toggleBackground("white"))
      }, 750)
    } else {
      // change bg and sticky header colour instantly,
      dispatch(toggleBackground("white"))
      setTimeout(() => {
        dispatch(toggleStickyHeaderColour("white"))
      }, 1000)
    }
  }, [])

  useEffect(() => {
    // on component mount get the space between hero and bottom of viewport
    setHeroHeight(window.outerHeight - sectionHero.current.clientHeight + 100)

    setTimeout(() => {
      // Remove this height (which reveals content below with a transition)
      setHeroHeight(null)
    }, 1500)
  }, [])

  useEffect(() => {
    toggleMainContent(true)
  }, [])

  // Hide main content on component render to stop flash of content
  const setMainContentOpacity = mainContent ? 1 : 0

  if (!projectFields) return <p>There was a problem, please refresh</p>

  return (
    <SiteLayout location={location} transitionStatus={transitionStatus}>
      <Seo
        seoTitle={seo.title ? seo.title : title}
        seoDesc={seo.metaDesc ? seo.metaDesc : null}
        ogTitle={seo.opengraphTitle ? seo.opengraphTitle : seo.title}
        ogDesc={
          seo.opengraphDescription ? seo.opengraphDescription : seo.metaDesc
        }
        ogImage={
          seo.opengraphImage && seo.opengraphImage.localFile
            ? seo.opengraphImage.localFile.url
            : null
        }
      />

      <section
        style={{
          position: "relative",
          paddingBottom: heroHeight, // use of this with transition creates the sliding content animation
          transition:
            heroHeight === null
              ? `padding-bottom 1.5s cubic-bezier(0.645, 0.045, 0.355, 1.000)`
              : null,
        }}
        className="section section--hero"
        ref={sectionHero}
      >
        <div className="container-fluid">
          <div className="row middle-bp4 intro-row">
            <div className="col-bp1-12 col-bp4-5">
              <Reveal delay=".5">
                <h1 className="intro-brand">
                  {projectFields.projectLogoLightMode?.sourceUrl ? (
                    <img
                      src={projectFields.projectLogoLightMode?.sourceUrl}
                      alt={projectFields.projectLogoLightMode?.altText}
                    />
                  ) : (
                    title
                  )}
                </h1>
              </Reveal>
            </div>
            <div className="col-bp1-12 col-bp2-9 col-bp4-7">
              <Reveal delay=".75">
                <h2 className="intro-title intro-title--project intro-title--space">
                  {projectFields.projectSlogan}
                </h2>
              </Reveal>
              <Reveal delay="1">
                <div
                  className="intro intro--project intro--tight"
                  dangerouslySetInnerHTML={{
                    __html: projectFields.projectIntro,
                  }}
                />
              </Reveal>
            </div>
          </div>
        </div>
      </section>

      <div
        id="main"
        className="main-content main-content--project"
        style={{
          opacity: isJsEnabled ? setMainContentOpacity : 1,
        }}
      >
        {projectFields.projectHeroSelection === "video" ? (
          <section className="section section--full-video">
            <div className="section__video">
              <iframe
                src={`https://player.vimeo.com/video/${projectFields.projectHeroVideo}?background=1`}
                width="640"
                height="360"
              ></iframe>
            </div>
          </section>
        ) : (
          <section
            className="section section--full-image"
            style={{
              backgroundImage: `url(${
                projectFields.projectMainImage1
                  ? projectFields.projectMainImage1?.localFile?.childImageSharp
                      .fixed?.src
                  : null
              })`,
            }}
          ></section>
        )}

        <section className="section section--black section--space-bottom section--pull-up">
          <div className="container-fluid">
            <div className="row">
              <div className="col-bp1-12 col-bp5-6">
                <Reveal delay=".25">
                  <h2 className="title">{projectFields.projectSubHeading}</h2>
                </Reveal>
              </div>
            </div>
            <div className="row">
              <div className="col-bp1-12 col-bp4-4">
                <Reveal delay=".5">
                  <div
                    className="entry entry--project"
                    dangerouslySetInnerHTML={{
                      __html: projectFields.projectDescriptionCol1,
                    }}
                  ></div>
                </Reveal>
              </div>
              <div className="col-bp1-12 col-bp4-4">
                <Reveal delay=".75">
                  <div
                    className="entry entry--project"
                    dangerouslySetInnerHTML={{
                      __html: projectFields.projectDescriptionCol2,
                    }}
                  ></div>
                </Reveal>
              </div>
              <div className="col-bp1-12 col-bp4-4">
                <Reveal delay="1">
                  <div className="subnav-container">
                    <h3 className="subnav__title">Services delivered</h3>
                    <nav className="subnav">
                      <ul>
                        {projectFields.projectSubnav
                          ? projectFields.projectSubnav.map(item => {
                              return (
                                <li key={item.projectSubnavItem.id}>
                                  <PageLink
                                    url={item.projectSubnavItem.uri}
                                    dispatch={dispatch}
                                    colour={
                                      item.projectSubnavItem.ancestors.nodes[0]
                                        .slug === "creative"
                                        ? "blue"
                                        : "green"
                                    }
                                    menuLink={false}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item.projectSubnavItem.title,
                                      }}
                                    ></span>
                                  </PageLink>
                                </li>
                              )
                            })
                          : null}
                      </ul>
                    </nav>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        {projectFields.projectContentBlocks
          ? projectFields.projectContentBlocks.map((block, i) => {
              return (
                <section key={i} className="section section--content-block">
                  <div className="container-fluid">
                    <div
                      className={`row middle-bp2 ${
                        i === 1 ? `between-bp2` : ``
                      }`}
                      style={{
                        flexFlow: i === 1 ? `row-reverse wrap` : `row wrap`,
                      }}
                    >
                      <div className="col-bp1-12 col-bp2-6">
                        <ProjectMedia data={block} />
                      </div>
                      <div className="col-bp1-12 col-bp2-5">
                        <Reveal delay=".5">
                          <div
                            className="entry entry--project entry--project-text"
                            dangerouslySetInnerHTML={{
                              __html: block.projectContentBlockDesc,
                            }}
                          ></div>
                        </Reveal>
                      </div>
                    </div>
                  </div>
                </section>
              )
            })
          : null}

        <section
          className="section section--full-image section--pull-up"
          style={{
            backgroundImage: `url(${
              projectFields.projectMainImage2
                ? projectFields.projectMainImage2?.localFile?.childImageSharp
                    ?.fixed?.src
                : null
            })`,
          }}
        ></section>

        <section className="section section--video">
          <div className="container-fluid">
            <div className="row">
              <div className="col-bp1-12">
                {projectFields.projectModuleSelection === "video" ? (
                  <div className="project-video project-video--wide">
                    <iframe
                      src={`https://player.vimeo.com/video/${projectFields.projectVideo}?&title=0&byline=0&portrait=0&color=1faa9f`}
                      width="1920"
                      height="1080"
                      allow="fullscreen; picture-in-picture"
                    ></iframe>
                  </div>
                ) : (
                  <Slider data={projectFields.projectSlider} theme="dark" />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container-fluid">
            {projectFields.projectFurtherDetail ? (
              <div className="row">
                <div className="col-bp1-12 col-bp2-8 col-bp4-6">
                  <Reveal delay=".25">
                    <div
                      className="entry entry--project entry--space"
                      dangerouslySetInnerHTML={{
                        __html: projectFields.projectFurtherDetail,
                      }}
                    ></div>
                  </Reveal>
                </div>
              </div>
            ) : null}
            <div className="quote-container">
              <div className="row middle-bp2">
                <div className="col-bp1-12 col-bp2-7 col-bp5-6">
                  <Reveal delay=".25">
                    <div className="project-image">
                      <Img
                        fluid={
                          projectFields.projectQuoteImage
                            ? projectFields.projectQuoteImage.localFile
                                .childImageSharp.fluid
                            : null
                        }
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt={projectFields?.projectQuoteImage?.altText}
                        fadeIn={false}
                      />
                    </div>
                  </Reveal>
                </div>
                <div className="col-bp1-12 col-bp2-5 col-bp5-5">
                  <Reveal delay=".5">
                    <blockquote className="quote">
                      <div className="quote__body">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: projectFields.projectQuote,
                          }}
                        ></p>
                      </div>
                      <footer>
                        <p>{projectFields.projectQuoteAuthor}</p>
                        <p>{projectFields.projectQuoteRole}</p>
                      </footer>
                    </blockquote>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </section>

        {projectFields.projectRegions
          ? projectFields.projectRegions.map((region, i) => {
              return (
                <ProjectRegionDisplay
                  key={i}
                  index={i}
                  total={projectFields.projectRegions.length - 1}
                  region={region}
                />
              )
            })
          : null}

        <section className="section section--space-top section--no-space-bottom section--black">
          <div className="container-fluid">
            <div className="row middle-bp3">
              <div className="col-bp1-12 col-bp3-7">
                <Reveal delay=".25">
                  <h2
                    className="title title--project-footer"
                    dangerouslySetInnerHTML={{
                      __html:
                        allWp.edges[0].node.options.options.servicesSlogan,
                    }}
                  />
                </Reveal>
              </div>
              <div className="col-bp1-12 col-bp3-5">
                <Reveal delay=".5">
                  <PageLink
                    className="link link--right"
                    url="/work"
                    dispatch={props.dispatch}
                    colour="white"
                    menuLink={false}
                  >
                    <span>View all projects</span> <Chevron />
                  </PageLink>
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        {featuredWork?.featuredWorkItems ? (
          <FeaturedWork featuredWork={featuredWork} lightMode />
        ) : null}
      </div>
    </SiteLayout>
  )
}

export default connect(
  state => ({
    background: state.background.background,
    breadcrumbs: state.breadcrumbs.breadcrumbs,
  }),
  null
)(Project)

export const projectQuery = graphql`
  query GET_PROJECT($id: String!) {
    allWp {
      edges {
        node {
          options {
            options {
              servicesSlogan
            }
          }
        }
      }
    }
    allWpProject(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          slug
          seo {
            title
            metaDesc
            opengraphTitle
            opengraphDescription
            opengraphImage {
              localFile {
                url
              }
            }
          }
          projectFields {
            projectBreadcrumbTitle
            projectLogoLightMode {
              altText
              sourceUrl
            }
            projectSlogan
            projectIntro
            projectHeroSelection
            projectHeroVideo
            projectMainImage1 {
              altText
              sourceUrl
              mimeType
              localFile {
                childImageSharp {
                  fixed(width: 1920) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
            projectSubHeading
            projectDescriptionCol1
            projectDescriptionCol2
            projectSubnav {
              projectSubnavItem {
                ... on WpPage {
                  id
                  title
                  uri
                  ancestors {
                    nodes {
                      slug
                    }
                  }
                }
              }
            }
            projectContentBlocks {
              projectContentBlockDesc
              projectContentBlockMediaSelection
              projectContentBlockVideo
              projectContentBlockImage {
                altText
                sourceUrl
                mimeType
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
            projectMainImage2 {
              altText
              sourceUrl
              mimeType
              localFile {
                childImageSharp {
                  fixed(width: 1920) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
            projectModuleSelection
            projectVideo
            projectSlider {
              altText
              sourceUrl
              mimeType
              localFile {
                childImageSharp {
                  fixed(width: 1536) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
            projectFurtherDetail
            projectQuote
            projectQuoteAuthor
            projectQuoteRole
            projectQuoteImage {
              altText
              sourceUrl
              mimeType
              localFile {
                childImageSharp {
                  fluid(maxWidth: 3000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            projectRegions {
              projectRegionSelection
              projectRegionTextBlock
              projectRegionImage {
                altText
                sourceUrl
                mimeType
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 3000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          featuredWork {
            featuredWorkItems {
              featuredWorkItem {
                ... on WpProject {
                  id
                  slug
                  uri
                  title
                  projectFields {
                    projectLogoDarkMode {
                      altText
                      sourceUrl
                    }
                    projectPreviewImage {
                      altText
                      sourceUrl
                      mimeType
                      localFile {
                        childImageSharp {
                          fluid(
                            maxHeight: 480
                            maxWidth: 680
                            quality: 85
                            cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    projectShortDesc
                    projectSubnav {
                      projectSubnavItem {
                        ... on WpPage {
                          id
                          title
                          uri
                          parent {
                            ... on WpPage {
                              slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
