import React from 'react'
import Img from 'gatsby-image'

import Reveal from "../Waypoints"

const ProjectMedia = props => {
  const { 
    wide,
    data: {
      projectContentBlockMediaSelection, 
      projectContentBlockVideo, 
      projectContentBlockImage, 
      projectRegionImage,
    }
  } = props

  // If we have a video render it
  if (projectContentBlockMediaSelection === 'video') {
    if (projectContentBlockVideo) {
      return <Reveal delay=".25">
        <div className="project-video project-video--bg">
          <iframe src={`https://player.vimeo.com/video/${projectContentBlockVideo}?background=1`} width="640" height="360"></iframe>
        </div>
      </Reveal>
    }
  } else {

    // Or if it's an image, determine between a region image and a content block,
    // Also filter out gifs and display them with their sourceUrl and not 
    // as responsive images

    if (projectContentBlockImage) {
      if (projectContentBlockImage.mimeType === 'image/gif') {
        return <Reveal delay=".25"><div className="project-image">
          <img className="project-image__gif" src={projectContentBlockImage.sourceUrl} alt={projectContentBlockImage.altText} />
        </div></Reveal>
      } 
      return <Reveal delay=".25">
        <div className={`project-image ${wide ? `project-image--wide` : ``}`}>
          <Img
            fluid={projectImgPath(projectContentBlockImage)}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={projectContentBlockImage.altText}
            fadeIn={false}
          />
        </div>
      </Reveal>
    } else if (projectRegionImage) {
      if (projectRegionImage.mimeType === 'image/gif') {
        return <Reveal delay=".25"><div className="project-image">
          <img className="project-image__gif" src={projectRegionImage.sourceUrl} alt={projectRegionImage.altText} />
        </div></Reveal>
      } 
      return <Reveal delay=".25">
        <div className={`project-image ${wide ? `project-image--wide` : ``}`}>
          <Img
            fluid={projectImgPath(projectRegionImage)}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={projectRegionImage.altText}
            fadeIn={false}
          />
        </div>
      </Reveal>
    }

  }
  return false
}

const projectImgPath = image => {
  if (image.localFile != null) {
    return image.localFile.childImageSharp.fluid
  }
  return false
}

export default ProjectMedia