import React, { useEffect, useRef } from "react"
import Swiper from "react-id-swiper"

const Slider = props => {
  const container = useRef(null)
  const controls = useRef(null)

  // Swiper slider parameters
  const sliderParams = {
    pagination: {
      el: ".slider__pager",
      clickable: true,
    },
    navigation: {
      nextEl: ".slider__next",
      prevEl: ".slider__prev",
    },
    renderPrevButton: () => (
      <a className="slider__prev">
        <svg width="18px" height="36px" viewBox="0 0 19 36">
          <path d="M17.7,35.4l-17-17c-0.3-0.3-0.3-0.7,0-1 l17-17" />
        </svg>
      </a>
    ),
    renderNextButton: () => (
      <a className="slider__next">
        <svg width="18px" height="36px" viewBox="0 0 19 36">
          <path d="M0.5,0.5l17,17c0.3,0.3,0.3,0.7,0,1l-17,17" />
        </svg>
      </a>
    ),
    effect: "fade",
  }

  useEffect(() => {
    // Wrap Carousel controls in a container for style manipulation
    let wrap = container.current
    const pager = wrap.querySelector(".slider__pager")
    const next = wrap.querySelector(".slider__next")
    const prev = wrap.querySelector(".slider__prev")
    controls.current.append(prev, pager, next)
  }, [])

  const sliderClasses =
    props.theme === "dark" ? `slider slider--dark` : `slider`
  const sliderControls = props.data?.length > 1 ? `slider--controls` : ``

  return (
    <div className={`${sliderClasses} ${sliderControls}`} ref={container}>
      <Swiper {...sliderParams}>
        {props.data?.map((slide, i) => {
          return (
            <div key={i} className="slide">
              {slide.localFile ? (
                <img
                  className="slide__image"
                  src={slide.localFile?.childImageSharp?.fixed?.src}
                  alt={slide.altText}
                />
              ) : null}
            </div>
          )
        })}
      </Swiper>
      <div className="slider__controls" ref={controls} />
    </div>
  )
}

export default Slider
