import React from "react"

import Reveal from "../Waypoints"
import ProjectMedia from "./ProjectMedia"

const ProjectRegionDisplay = props => {
  const { index, total, region } = props

  if (region.projectRegionSelection === 'full_width') {
    return (
      <section
        className={`section section--full-image section--no-space-bottom section--region--full ${index === 0 ? `section--pull-up` : ``}`}
        style={{ backgroundImage: `url(${region.projectRegionImage ? region.projectRegionImage.sourceUrl : null})` }}
        ></section>
    )
  } else if (region.projectRegionSelection === 'text_based') {
    return (
    <section className={`section section--region--text ${index === 0 ? `section--region--first` : ``}`}>
      <div className="container-fluid">
        <div className="row middle-bp2">
          <div className="col-bp1-12 col-bp2-6">
            <ProjectMedia data={region} region />
          </div>
          <div className="col-bp1-12 col-bp2-5">
            <Reveal delay=".5">              
              <div
                className="entry entry--project entry--project-text"
                dangerouslySetInnerHTML={{
                  __html: region.projectRegionTextBlock,
                }}
              >
                </div>
            </Reveal>
          </div>
        </div>
      </div>
      </section>    
    )
  } else if (region.projectRegionSelection === 'fixed_width') {
    return (
      <section className={`section section--region--fixed-image ${index === 0 ? `section--region--first` : ``} ${index === total ? `section--region--last` : ``}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-bp1-12">
              <ProjectMedia data={region} wide />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ProjectRegionDisplay